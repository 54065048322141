
.promotesPage{
    footer{
        padding-top: 170px;
        @include media-breakpoint-down(xl) {
            padding-top: 70px;
        }
        @include media-breakpoint-down(sm) {
            padding-top: 60px;
        }
    }
}

.promotes{
    background-image: url('../#{$imageurl}/promotes-left-bottom.png'),url('../#{$imageurl}/promotes-left-top.png'),url('../#{$imageurl}/promotes-right-bottom.png'),url('../#{$imageurl}/promotes-right-top.png');
    background-position: left bottom,left top, right bottom, right top;
    background-repeat: no-repeat;
    background-size: auto;
    &_container{max-width: 884px;margin: 0 auto;}
    &_inner{padding: 86px 0px;}
    &_cnt{
        padding: 0px 35px 52px; margin-top: 118px; 
        p{font-size: 32px; line-height: 40px; color: color(50); padding-top: 26px; }
        &_head{font-size: 50px; font-family: font(sb); color: color(500); background-color: color(50); 
            padding: 20px 26px; border: 8px solid color(500); display: inline-block; 
        &-colchng{color: #4CC6F4; border-color: #4CC6F4;}
        }
        &:nth-child(even){background-color: color(500);}
        &:nth-child(odd){background-color: #4CC6F4;}
        &_inner{margin-top: -60px;}
    }
    @media screen and (max-width: 1599px) {
        &_cnt{ 
            padding: 0px 25px 40px;margin-top: 85px;
            &:not(:last-child){margin-bottom: 0px;}
            &_head {font-size: 40px;padding: 16px 20px;border: 6px solid;}
            &_inner {
                margin-top: -45px;
            }
        }
    }
    @include media-breakpoint-down(xxl) {
        &_cnt{
            p {font-size: 26px;padding-top: 15px;}
            &_head{font-size: 36px;border: 5px solid;}
            &_inner {
                margin-top: -42px;
            }
        }
    }
    @include media-breakpoint-down(xl) {
        background-size: 315px;
        &_inner{padding: 67px 0px 75px;}
        &_cnt{
            margin-top: 64px;
            padding: 0px 20px 30px;
            &_head {
                font-size: 27px;
                padding: 12px 32px 16px;
                line-height: 32px;
            }
            p{font-size: 20px; line-height: 24px;}
            &_inner {
                margin-top: -33px;
            }
            &:first-child{margin-top: 74px;}
    
        }
        &_container{ max-width: 530px;}
    
    }
    @include media-breakpoint-down(sm) {
        background-image: none;
        &.promotesmobile{ 
            background-image: url('../#{$imageurl}/promotes-mobile.png');
            background-size: 100% 100%;
            background-position: center;
            background-repeat: no-repeat;
        }
        &_inner{padding: 20px 0px 18px;}
        &_cnt{
            padding: 0px 13px 10px; margin-top: 60px;
            p {font-size: 14px;padding-top: 10px;}
            &_head{font-size: 18px;border: 3px solid; padding: 8px 18px 10px;}
            &:first-child{margin-top: 68px;}
            &_inner {
                margin-top: -27px;
            }
        }
        &_container{ max-width: 345px;}
    }
}
.commonhead{
    &_title{
        font-size: 64px; line-height: 67px; font-family: font(sb); color: color(550); margin-bottom: 18px;
    }
    &_subtitle{font-size: 40px; line-height: 48px; color: color(500); display: block;
        width: 100%;max-width: 780px;margin: 0 auto;
        span{background: white;position: relative;z-index: 2;}
        &::after,&::before{position: absolute; content: ""; width: 164px; height: 2px; background-color: color(500); top: 50%; transform: translateY(-50%);}
        &::after{left: 0px;}
        &::before{right: 0px;}
    }
    @media screen and (max-width: 1599px) {
        &_title{
            font-size: 55px;
            line-height: 55px;
        }
        &_subtitle{ font-size: 36px; max-width: 750px;}
    }
    @include media-breakpoint-down(xxl) {
        &_title{font-size: 48px;line-height: 48px;}
        &_subtitle{ max-width: 690px;
            &::before,&::after{width: 125px;}
        }
    }
    @include media-breakpoint-down(xl) {
        &_title{font-size: 40px;line-height: 48px;}
        &_subtitle{ 
            max-width: 560px; font-size: 30px;
            &::before,&::after{width: 125px;}
        }
    }
    @include media-breakpoint-down(sm) {
        &_inner{padding: 20px 0px 18px;}
        &_title{font-size: 32px;line-height: 40px; margin-bottom: 6px;}
        &_subtitle{ 
            max-width: 350px; font-size: 18px; line-height: 21px;
            &::before,&::after{width: 70px;}
        }
    }


}
.howitworkPage{
    .howdrWorks{
        padding: 85px 0 170px;
        //  background: url('../#{$imageurl}/howdrworks-left-bg.svg'), url('../#{$imageurl}/howdrWorks-right-bg.svg') ;
        background-position: top left, top right;background-repeat:no-repeat;
        h1{
            font-size:64px;color:#4CC6F4; margin-bottom: 65px;
            span{color:color(500);}
        }
        .container{
            @media (min-width:1550px) {
                max-width: 1512px;
            }
        }
        @media (max-width:1599px){
            h1{font-size: 36px;}
        }
        @include media-breakpoint-down(sm){
            padding-top: 20px;
            h1{font-size: 32px; margin: 0 auto;margin-bottom: 20px;}
        }
    
    
        &__head {
            font-family: font(sb);
            font-size: 36px;
            text-align: center;
            margin-bottom: 60px;
            z-index: 1;
            position: relative;
        }
    
        &__box {
            background-color: #0081C7;
            padding: 24px 30px;
            position: relative;
            height:410px;
            z-index: 1;
            overflow: hidden;
            color:color(50);
            h3 {
                font-size:28px;
                line-height:35px;
                font-family: font(bd);
                margin-bottom: 15px;
            }
    
            p {
                font-size: 19px;line-height:28px;
                font-family: font(rg);
                letter-spacing: 0.016em;
            }
    
            &__over {
                background-color: #0081C7;
                text-align: right;
                position: absolute;
                width: 100%;
                left: 0;
                height: 198px;
                bottom: 0;
                padding-right: 32px;
                padding-bottom: 32px;
                display: flex;
                justify-content: end;
                align-items: end;
                @include transition(0.3s);
    
                &__inner {
    
                    // position: relative;
                    &:after {
                        content: '';
                        height: 3px;
                        width: 100%;
                        background: color(50);
                        -webkit-transform: skew(0deg, -10deg);
                        transform: skew(0deg, -15deg);
                        position: absolute;
                        top: -40px;
                        left: 0;
                    }
    
                    &:before {
                        background-color: #0081C7;
                        content: '';
                        height: 100px;
                        width: 100%;
                        top: -37px;
                        left: 0;
                        position: absolute;
                        -webkit-transform: skew(0deg, -10deg);
                        transform: skew(0deg, -15deg);
                    }
                }
    
                img {
                    max-width: 299px;
                    position: relative;
                }
            }
    
            &:hover{
                .howdrWorks__box__over{
                    transform: translateY(300px);
                }
            }
        }
        // media
        @include media-breakpoint-down(xxl) {
            padding:53px 0px 60px;
            &:after,
            &:before {
                background-size: 220%;
            }
            &__box{
                h3{
                    font-size: 22px;
                }
                p{
                    font-size: 15px;
                }
                &__over{
                    img{
                        max-width: 285px;
                    }
                }
            }
        }
        @include media-breakpoint-down(xl) {
            padding: 65px 0; background-size: 300px;
            h1{margin-bottom: 50px;}
            &::after , &:before{display:none;}
            .container{max-width:100%;}
            &__box__over__inner:before{display:none;}
            &__box__over__inner:after{display:none;}
            .howdrWorks__box__over__inner{img{max-height:268px;margin:50px auto 0;display:block;}}
            &__box{
                padding:0;height:auto;
                p{
                    font-size:20px;line-height:30px;margin-bottom:40px;
                }
            }
            &_accordion{
                .accordion{
                    z-index:1;
                    &-button{
                        padding:24px 22px;font-size:24px;line-height:24px;font-family:font(sb);
                        background-color:color(550);color:color(50);
                        &:focus{box-shadow:none;border:0;}
                        &:after{
                            background:url('../#{$imageurl}/down-arrow.svg'); background-repeat: no-repeat;background-size:100%;height:10px;
                        }
                    }
                    &-body{
                        padding:24px 22px;
                    }
                    &-item{
                        background-color:color(550);border:0;
                        &:not(:last-child){margin-bottom:40px;}
                    }
                    
                }
            }
        }
        @include media-breakpoint-down(sm) {
            // padding: 20px 0;background-image: url('../#{$imageurl}/howdrworks-left-mobile-bg.svg'), url('../#{$imageurl}/howdrWorks-right-mobile-bg.svg') ;
            background-position: left -110px, right -110px;
            h1{}
            &__head{
                font-size:16px;line-height:24px;margin-bottom:30px;
            }
            &__box{
                p{
                    font-size:20px;line-height:30px;margin-bottom:40px;
                }
            }
            &_accordion{
                .accordion{
                    &-button{
                        font-size:22px;line-height:26px;
                    }
                    &-item{
                        &:not(:last-child){margin-bottom:24px;}
                    }
                    
                }
            }
        }
        // media !!
    
    }




    // process
    .process{
        padding: 100px 0 135px;background-color: #BEECFC;
        background-image: url('../#{$imageurl}/process-left-bg.svg'), url('../#{$imageurl}/process-right-bg.svg') ;
        background-position: top left, top right;background-repeat:no-repeat;
        h2{font-size:64px;color:#0081C7; margin-bottom: 125px;}
        &_wrap{
            width: 885px;
            li{
                padding:86px 35px 52px 35px;font-size: 32px; position: relative;
                .count{
                    width: 102px;height: 102px;font-size: 50px;font-family: font(sb);
                    color:color(500);@include flexcenter(both); margin:0 auto;
                    @include absolutecenter(x);top:-51px;
                }
                img{
                    @include absolutecenter(x); bottom: -70px; width: 90px;
                }
                &:nth-child(odd){
                    background-color: color(500); color:color(50);
                    .count{background-color: color(50); color:color(500);} 
                }
                &:nth-child(even){
                    background-color: color(50); color:color(500);
                    .count{background-color: color(500);color:color(50);} 
                }
                &:not(:last-child){margin-bottom: 160px;}
            }
        }
        @media (max-width:1599px){
            h2{font-size: 36px;}
        }
        @include media-breakpoint-down(xl){
            padding: 25px 0 80px; background-size: 215px;
            h2{margin-bottom: 40px;}
            &_wrap{
                width: 530px;
                li{
                    font-size: 18px; padding:26px 29px 13px 29px; 
                    .count{width: 40px;height: 40px;font-size: 20px; top:-20px}
                    &:not(:last-child){margin-bottom: 65px;}
                    img{bottom:-26px;width: 36px;}
                }
            }
        }
        @include media-breakpoint-down(sm){
            padding: 40px 0 60px;
            h1{font-size: 32px; margin-bottom: 50px;}
            &_wrap{
                width: 100%;
                li{padding:30px 13px 13px;}
            }
        }
    }
    .footer{
        padding:100px 0;
        @include media-breakpoint-down(xl){
            padding:45px 0;
        }
    }
}
.btn {
  font-size: 20px;
  padding: 10px 30px;
  font-family: font(sb);
  border-radius: 0px;
  text-transform: uppercase;
  @include flexcenter(both);
  height: 56px;
  &:focus {
    box-shadow: none;
  }
  &.disabled,
  &:disabled {
    opacity: 0.4;
    pointer-events: none;
  }
  &-primary {
    border-color: color(500);
    color: color(50);
    background-color: color(500);
    &:hover,
    &:focus,
    &:active,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active:focus {
      border-color: transparent;
      background: rgba($color: color(500), $alpha: 0.9);
      color: color(50);
      outline: none;
      box-shadow: none !important;
    }
    &-outline {
      border-color: color(500);
      color: color(900);
      background-color: transparent;
      &:hover,
      &:focus,
      &:active {
        border-color: color(500);
        color: color(50);
        background-color: color(500);
      }
    }
  }

  @media (max-width: 1599px){
    font-size: 16px; height:48px;
  }
  
}

    .bannerSec{
        padding-top: 0px; height: 100vh;
        video{width: 100%;object-fit: cover; height: 100vh;}
        &_txt{
            h1{font-size: 67px;line-height: 70px;font-family: font(avlt);color: color(500);}
            h2{font-size: 164px; font-family: font(bd);  color: color(500); line-height: 172px;}
        }
        &_arrow{
            background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 42.43%, color(50) 94.17%);
            height: 180px;
            position: absolute;
            bottom: 0px;
            width: 100%;
            a{
                cursor: pointer;
            }
            img{position: absolute;bottom: 30px;left: 50%;transform: translateX(-50%);}
        }
        &_video{position: absolute; width: 100%;}
        @media screen and (max-width: 1799px) {
            &_txt{ h2{font-size: 120px; line-height: 120px;} }
           
        }
        @media screen and (max-width: 1599px) {
            &_txt{ 
                h1{font-size: 50px;}
                h2{font-size: 110px;line-height: 110px;}
            }
        }
        // @include media-breakpoint-down(xl) {
        //     &_txt{ 
        //         h1{font-size: 40px; line-height: 42px;}
        //         h2{font-size: 100px;line-height: 100px;}
        //     }
        // }
        @include media-breakpoint-down(xl) {
            height: 497px;
            video{
                height: 100%;
            }
            &_txt{ 
                h1{font-size: 40px; margin-bottom: 0px !important;}
                h2{font-size: 96px;line-height: 100px;}
            }
        }

        @include media-breakpoint-down(sm) {
            &_txt{ 
                h1{font-size: 24px; line-height: 25px;}
                h2{font-size: 52px;line-height: 55px;}
            }
        }
    
    }
    .marketingsales{
        &_left{
            width: 50%;
            padding: 0px 133px 0px 215px;
            h2{
                font-size: 64px; font-family: font(bd); line-height: 96px; color: color(600);
                span{color: color(500);}
            }
        }
        &_right{
            position: relative;
            padding: 60px 72px;
            width: 50%;
            background-image: url('../#{$imageurl}/marketing-bg.png'); background-position: right bottom; background-size: auto; background-repeat: no-repeat;
            &_revenue{box-shadow: 0px 8px 32px rgba(32, 37, 40, 0.3);padding: 30px 40px; background-color: color(400); color: color(50); width: max-content;
                span{padding-left: 35px; font-size: 40px; line-height: 49px; font-family: font(sb);}
                em{font-size: 50px;}
                &:not(:last-child){margin-bottom: 40px;}
            }
            &::after{
                content: "";
                top: 0px;
                right: 0px;
                position: absolute;
                width: 100%;
                height: 100%;
                background: linear-gradient(180deg, color(550) 0%, color(600) 100%);
                z-index: -1;
            }
        }
        @media screen and (max-width: 1799px) {
           &_left{padding: 0px 100px 0px 160px;}
        }
        @media screen and (max-width: 1699px) {
            &_left{
                h2{font-size: 55px;line-height: 70px;}
            }
            &_right{padding: 40px;}
            
        }
        @include media-breakpoint-down(xxl) {
            &_left{padding: 0px 60px 0px 100px;
            h2{font-size: 45px;line-height: 58px;}}
            &_right{
               background-size: 45%;
                &_revenue{
                    padding: 30px;
                    &:not(:last-child){margin-bottom: 20px;}
                    em{font-size: 40px;}
                    span{
                        padding-left: 15px;font-size: 30px;line-height: 30px;
                    }
                }
            }
        }
        // @include media-breakpoint-down(xl) {
        //     &_right{
        //         &_revenue{ padding: 20px;
        //             span{font-size: 25px; line-height: 25px;}
        //             em{font-size: 30px;}
        //         }
        //     }
        //     &_left{
        //         padding: 0px 30px 0px 80px;
        //         h2 {
        //             font-size: 38px;
        //             line-height: 50px;
        //         }
        //     } 
        // }
        // @include media-breakpoint-down(lg) {
        //     &_left{
        //         padding: 0px 20px 0px 40px;
        //     }
        // }
        @include media-breakpoint-down(xl) {
            padding: 83px 0px 72px;
            &_left{
                padding: 0px 45px 0px 16px;
                h2{font-size: 32px; line-height: 48px;}
            }
            &_right{
                padding: 24px 28px; background-size: 165px;
                &_revenue{
                    padding: 14px;
                    &:not(:last-child){margin-bottom: 15px;}
                    span{font-size: 16px; line-height: 19px; padding-left: 10px;}
                    em{font-size: 22px;}
                }
            }
        }
        @include media-breakpoint-down(md) {
            &_left{
                padding: 0px 12px 0px 16px;
            }
        }
        @include media-breakpoint-down(sm) {
            padding: 0px;
            &_txt{ 
                h1{font-size: 24px; line-height: 25px;}
                h2{font-size: 52px;line-height: 55px;}
            }
            &_left,&_right{
                width: 100%;
            }
            &_left{text-align: center; margin-bottom: 20px;}
        }
    }
    .dolyalrefer{
        h4,h3,a{margin: 80px 0px 70px; font-size: 48px; line-height: 72px; color: color(500);}
        h4{ span{ color: color(600); font-family: font(bd);} }
        h3{padding: 0px 18px;}
        &_network{
            position: relative; display: block;width: 100%; max-width: 685px; margin: 0 auto;
            &:after,&:before{content: ""; position: absolute; width: 90px; background-color: color(500); height: 3px; top: 50%;transform: translateY(-50%);}
            &:before{right: 0px;}
            &::after{left: 0px;}
        }
        &_poweredby{
            font-size: 29px; line-height: 43px; color: #B4B6B9; 
            span{margin: 0px 8px; font-size: 34px; line-height: 47px; color: #646568; font-family: font(sb);}
            img{width: 56px; height: 56px;}
        }
        @media screen and (max-width: 1699px) {
            h4,h3,a{margin: 40px 0px 55px; font-size: 40px; line-height: 60px;}
        }
        @include media-breakpoint-down(xxl) {
            h4,h3,a{margin: 20px 0px 60px;font-size: 30px; line-height: 45px;}
             &_network{
                &::before,&::after{width: 60px; height: 2px;  max-width: 450px;}
             }
        }
        @include media-breakpoint-down(xl) {
            padding: 13px 0px 65px;
            
            h4,h3,a{margin: 12px 0px 18px;font-size: 20px;line-height: 30px;}
            &_network{
                max-width: 323px;
            }
           
            &_network{
                &::before,&::after{width: 40px; max-width: 300px;}
             }
            &_poweredby{font-size: 15px; line-height: 21px;
                span{font-size: 17px; line-height: 24px;}
                img{width: 28px; height: 28px;}
            }
        }
        @include media-breakpoint-down(md) {
            padding: 19px 0px 65px;
        }
        @include media-breakpoint-down(sm) {
            padding: 33px 0px 129px;
            h4,h3,a{margin: 67px 0px 21px;font-size: 24px;line-height: 30px;}
            h3{font-size: 22px; padding: 0px 6px; padding-top: 5px;}
            &_txt{ 
                h1{font-size: 24px; line-height: 25px;}
                h2{font-size: 52px;line-height: 55px;}
            }
        }
    }


    .fp-slides{
        .fp-tableCell{
            display: block;
        }
    }
    
    .sliderSection{
        padding-top: 200px;
        @include media-breakpoint-down(xl){
            padding-top: 18px;
            .section_head{
                span{display:block; margin-bottom: 9px;}
            }
           
        }
    }
    
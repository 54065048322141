@font-face {
    font-family: 'icomoon';
    src:  url('#{$fonturl}/icomoon.eot?p6g88m');
    src:  url('#{$fonturl}/icomoon.eot?p6g88m#iefix') format('embedded-opentype'),
      url('#{$fonturl}/icomoon.ttf?p6g88m') format('truetype'),
      url('#{$fonturl}/icomoon.woff?p6g88m') format('woff'),
      url('#{$fonturl}/icomoon.svg?p6g88m#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  } 
  
  [class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .icon-arrow-down-circle:before {
    content: "\e900";
  }
  .icon-twitter:before {
    content: "\e901";
  }
  .icon-up-arrow:before {
    content: "\e902";
  }
  
  
.font-rg{
    font-family: font(rg);
}
.font-sb{
    font-family: font(sb);
}
.font-bd{
    font-family: font(bd);
}
.font-avlt{
    font-family: font(avlt);
}
.font-avmd{
    font-family: font(avmd);
}

.container{
    @include media-breakpoint-down(md){
        max-width: 100%;
    }
}

.w-505{
    max-width: 505px;
    width: 100%!important;
    @include media-breakpoint-down(xxl) {
        max-width: 350px;
    }
    @include media-breakpoint-down(xl) {
        max-width: 193px;
    }
}

@mixin transform($value) {
  -webkit-transform: $value;
  -ms-transform: $value; // IE9 only
  -o-transform: $value;
  transform: $value;
}
@mixin no-outline() {
  outline: none !important;
  -moz-outline: none !important;
  -webkit-outline: none !important;
  -ms-outline: none !important;
  -o-outline: none !important;
}
@mixin box-shadow($top, $left, $blur, $color, $inset: "") {
  -o-box-shadow: $top $left $blur $color #{$inset};
  -ms-box-shadow: $top $left $blur $color #{$inset};
  -webkit-box-shadow: $top $left $blur $color #{$inset};
  -moz-box-shadow: $top $left $blur $color #{$inset};
  box-shadow: $top $left $blur $color #{$inset};
}
@mixin border-all($width, $style, $color) {
  border-width: $width;
  border-style: $style;
  border-color: $color;
}
@mixin text-shadow($top, $left, $blur, $color, $inset: "") {
  -o-text-shadow: $top $left $blur $color #{$inset};
  -ms-text-shadow: $top $left $blur $color #{$inset};
  -webkit-text-shadow: $top $left $blur $color #{$inset};
  -moz-text-shadow: $top $left $blur $color #{$inset};
  text-shadow: $top $left $blur $color #{$inset};
}
@mixin border($style, $color, $sides) {
  @if ($style != "") {
    @if ($sides == "") {
      border: $style $color;
    } @else {
      @each $side in $sides {
        @if (
          $side ==
            "top" or
            $side ==
            "right" or
            $side ==
            "bottom" or
            $side ==
            "left"
        ) {
          border-#{$side}: $style $color;
        }
      }
    }
  }
}

@mixin animation($animation) {
  -webkit-animation: $animation;
  -o-animation: $animation;
  animation: $animation;
}
@mixin animation-name($name) {
  -webkit-animation-name: $name;
  animation-name: $name;
}
@mixin animation-duration($duration) {
  -webkit-animation-duration: $duration;
  animation-duration: $duration;
}
@mixin animation-timing-function($timing-function) {
  -webkit-animation-timing-function: $timing-function;
  animation-timing-function: $timing-function;
}
@mixin animation-delay($delay) {
  -webkit-animation-delay: $delay;
  animation-delay: $delay;
}
@mixin animation-iteration-count($iteration-count) {
  -webkit-animation-iteration-count: $iteration-count;
  animation-iteration-count: $iteration-count;
}
@mixin animation-direction($direction) {
  -webkit-animation-direction: $direction;
  animation-direction: $direction;
}
@mixin animation-fill-mode($fill-mode) {
  -webkit-animation-fill-mode: $fill-mode;
  animation-fill-mode: $fill-mode;
}

@mixin scale($ratio...) {
  -webkit-transform: scale($ratio);
  -ms-transform: scale($ratio); // IE9 only
  -o-transform: scale($ratio);
  transform: scale($ratio);
}

@mixin scaleX($ratio) {
  -webkit-transform: scaleX($ratio);
  -ms-transform: scaleX($ratio); // IE9 only
  -o-transform: scaleX($ratio);
  transform: scaleX($ratio);
}
@mixin scaleY($ratio) {
  -webkit-transform: scaleY($ratio);
  -ms-transform: scaleY($ratio); // IE9 only
  -o-transform: scaleY($ratio);
  transform: scaleY($ratio);
}
@mixin skew($x, $y) {
  -webkit-transform: skewX($x) skewY($y);
  -ms-transform: skewX($x) skewY($y);
  -o-transform: skewX($x) skewY($y);
  transform: skewX($x) skewY($y);
}
@mixin translate($x, $y) {
  -webkit-transform: translate($x, $y);
  -ms-transform: translate($x, $y); // IE9 only
  -o-transform: translate($x, $y);
  transform: translate($x, $y);
}
@mixin translate3d($x, $y, $z) {
  -webkit-transform: translate3d($x, $y, $z);
  transform: translate3d($x, $y, $z);
}
@mixin rotate($degrees) {
  -webkit-transform: rotate($degrees);
  -ms-transform: rotate($degrees); // IE9 only
  -o-transform: rotate($degrees);
  transform: rotate($degrees);
}
@mixin rotateX($degrees) {
  -webkit-transform: rotateX($degrees);
  -ms-transform: rotateX($degrees); // IE9 only
  -o-transform: rotateX($degrees);
  transform: rotateX($degrees);
}
@mixin rotateY($degrees) {
  -webkit-transform: rotateY($degrees);
  -ms-transform: rotateY($degrees); // IE9 only
  -o-transform: rotateY($degrees);
  transform: rotateY($degrees);
}

@mixin transform-origin($origin) {
  -webkit-transform-origin: $origin;
  -moz-transform-origin: $origin;
  -ms-transform-origin: $origin; // IE9 only
  transform-origin: $origin;
}

@mixin transition($transition...) {
  -webkit-transition: $transition;
  -o-transition: $transition;
  -moz-transition: $transition;
  transition: $transition;
}
@mixin transition-property($transition-property...) {
  -webkit-transition-property: $transition-property;
  transition-property: $transition-property;
}
@mixin transition-delay($transition-delay) {
  -webkit-transition-delay: $transition-delay;
  transition-delay: $transition-delay;
}
@mixin transition-duration($transition-duration...) {
  -webkit-transition-duration: $transition-duration;
  transition-duration: $transition-duration;
}
@mixin transition-timing-function($timing-function) {
  -webkit-transition-timing-function: $timing-function;
  transition-timing-function: $timing-function;
}
@mixin transition-transform($transition...) {
  -webkit-transition: -webkit-transform $transition;
  -moz-transition: -moz-transform $transition;
  -o-transition: -o-transform $transition;
  transition: transform $transition;
}
@mixin user-select($select) {
  -webkit-user-select: $select;
  -moz-user-select: $select;
  -ms-user-select: $select; // IE10+
  user-select: $select;
}

@mixin button-variant($color, $background, $border) {
  color: $color;
  background-color: $background;
  border-color: $border;

  &:hover,
  &:active,
  &:focus,
  &.focus &.active,
  .open > &.dropdown-toggle {
    color: $color;
    background-color: darken($background, 10%);
    border-color: darken($border, 12%);
  }
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    &:hover,
    &:focus,
    &.focus {
      color: $color;
      background-color: darken($background, 17%);
      border-color: darken($border, 25%);
    }
  }
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    background-image: none;
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &:hover,
    &:focus,
    &.focus {
      background-color: $background;
      border-color: $border;
    }
  }

  .badge {
    color: $background;
    background-color: $color;
  }
}
@mixin text-overflow() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
// Button sizes
@mixin button-size(
  $padding-vertical,
  $padding-horizontal,
  $font-size,
  $line-height,
  $border-radius
) {
  padding: $padding-vertical $padding-horizontal;
  font-size: $font-size;
  line-height: $line-height;
  border-radius: $border-radius;
}

// Flexbox display
@mixin flexbox() {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

// nowrap | wrap | wrap-reverse
@mixin flex-wrap($wrap) {
  -webkit-flex-wrap: $wrap;
  -moz-flex-wrap: $wrap;
  -ms-flex-wrap: $wrap;
  flex-wrap: $wrap;
}

// Axis Alignment
// - applies to: flex containers
// flex-start | flex-end | center | space-between | space-around
@mixin justify-content($justify) {
  -webkit-justify-content: $justify;
  -moz-justify-content: $justify;
  -ms-justify-content: $justify;
  justify-content: $justify;
  -ms-flex-pack: $justify;
}

// Packing Flex Lines
// - applies to: multi-line flex containers
// flex-start | flex-end | center | space-between | space-around | stretch
@mixin align-content($align) {
  -webkit-align-content: $align;
  -moz-align-content: $align;
  -ms-align-content: $align;
  align-content: $align;
}

// Cross-axis Alignment
// - applies to: flex containers
// flex-start | flex-end | center | baseline | stretch
@mixin align-items($align) {
  -webkit-align-items: $align;
  -moz-align-items: $align;
  -ms-align-items: $align;
  align-items: $align;
}

@mixin font-face(
  $font-family,
  $file-path,
  $weight: normal,
  $style: normal,
  $asset-pipeline: false
) {
  @font-face {
    font-family: $font-family;
    font-weight: $weight;
    font-style: $style;
    font-display: swap;
    @if $asset-pipeline == true {
      src: font-url("#{$file-path}.eot");
      src: font-url("#{$file-path}.eot?#iefix") format("embedded-opentype"),
        font-url("#{$file-path}.woff") format("woff"),
        font-url("#{$file-path}.ttf") format("truetype"),
        font-url("#{$file-path}.svg##{$font-family}") format("svg");
    } @else {
      src: url("#{$file-path}.eot");
      src: url("#{$file-path}.eot?#iefix") format("embedded-opentype"),
        url("#{$file-path}.woff") format("woff"),
        url("#{$file-path}.ttf") format("truetype"),
        url("#{$file-path}.svg##{$font-family}") format("svg");
    }
  }
}

@mixin absolutecenter($axis: "both") {
  position: absolute;
  @if $axis == "y" {
    top: 50%;
    transform: translateY(-50%);
  }
  @if $axis == "x" {
    left: 50%;
    transform: translateX(-50%);
  }
  @if $axis == "both" {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

// keyframes mixin

@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @-ms-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}

// flex center
@mixin flexcenter($axis: "both") {
  display: flex;
  @if $axis == "y" {
    align-items: center;
  }
  @if $axis == "x" {
    justify-content: center;
  }
  @if $axis == "both" {
    justify-content: center;
    align-items: center;
  }
}

// placeholder
@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}

@mixin text-clamp($lines: 2, $line-height: false) {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $lines;
  -moz-line-clamp: $lines;
  -ms-line-clamp: $lines;
  -o-line-clamp: $lines;
  text-overflow: ellipsis;
  @if $line-height {
    max-height: $line-height * $lines * 1px;
  }
}

.header{
    background-color: color(50);
    padding: 15px 0px;
    box-shadow: 0px 34px 38px rgba(0, 174, 239, 0.2);
    position: fixed;
    width: 100%;
    z-index: 9;
    top: 0;
    .container{
        max-width: 1820px;padding-left: 16px; padding-right: 16px;
        // @media screen and (max-width: 1599px) {
        //     max-width: 1400px;
        // }
        // @include media-breakpoint-down(xxl) {
        //     max-width: 1200px;
        // }
        // @include media-breakpoint-down(xl) {
        //     max-width: 1000px;
        // }
        
    }
    @include media-breakpoint-down(xl) {
        padding: 24px 0px;
        &_logo{
            img{
                width: 254px;
                height: 31px;
            }
        }
        .btn{
            font-size: 12px;
            line-height: 15px;
            height: 36px;
        }
    }
    @include media-breakpoint-down(sm) {
        padding: 27px 0px 12px;
        &_logo{
            img{
                width: 140px;
                height: 19px;
            }
        }
        .btn{
            font-size: 12px;
            line-height: 15px;
            padding: 8px 16px;
            height: 28px;
            width: 146px;
        }
    }

}
.affilliates {
  padding: 50px 0px 20px;
  &__boxWrapper {
    max-width: 1060px;
    margin: 130px auto 0px;
  }
  &__box {
    padding: 28px 30px 23px;
    text-align: center;
    color: color(50);
    background: #00aeef;
    max-width: 514px;
    margin: 0 auto;
    border: 5px solid #24cdff;
    height: 100%;
    @include box-shadow(0px, 10.4px, 15.6px, rgba(0, 184, 239, 0.4));
    h3 {
      font-family: font(bd);
      font-size: 32px;
      margin-bottom: 14px;

      span {
        font-size: 20px;
        display: block;
      }
    }

    p {
      font-family: font(rg);
      font-size: 26px;
      line-height: 32px;
      margin-bottom: 0;

      span {
        font-family: font(bd);
      }
    }

    &--right {
      background-color: #4cc6f4;

      h3 {
        margin-bottom: 20px;
      }
    }
  }
  // media
  @include media-breakpoint-down(xxl) {
    padding: 39px 0px 170px;
    &__boxWrapper {
      max-width: 1020px;
      margin: 76px auto 0px;
    }
    &__box {
      padding: 30px 40px 10px;
      h3 {
        font-size: 27px;
        span {
          font-size: 18px;
        }
      }
      p {
        font-size: 22px;
        line-height: 28px;
      }
    }
  }
  @include media-breakpoint-down(xl) {
    padding: 0px 0px 78px;
    &__boxWrapper {
      max-width: 530px;
      margin: 0px auto 0px;
    }
    &__box {
      padding: 15px;
      max-width: 260px;
      h3 {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 5px;
        span {
          font-size: 11px;
          line-height: 16px;
        }
      }
      p {
        font-size: 13px;
        line-height: 16px;
      }
    }
  }
  @include media-breakpoint-down(md) {
    padding: 35px 0;
    &__boxWrapper {
      margin: 15px auto 0px;
    }
    &__box {
      padding: 20px;
      max-width: 100%;
      h3 {
        font-size: 20px;
        line-height: 31px;
        span {
          font-size: 13px;
          line-height: 20px;
        }
      }
      p {
        font-size: 18px;
        line-height: 21px;
        margin-bottom: 0;
      }
    }
  }
  // media !!
}

.section_head {
  font-size: 96px;
  font-family: font(bd);
  color: #006094;
  text-align: center;

  span {
    font-size: 36px;
    font-family: font(bd);
    color: color(500);
    display: block;
    font-weight: 700;
  }
  // media
  @include media-breakpoint-down(xxl) {
    font-size: 54px;
    span {
      font-size: 30px;
    }
  }
  @include media-breakpoint-down(xl) {
    font-size: 36px;
    line-height: 40px;
    span {
      font-size: 16px;
      line-height: 18px;
    }
  }
  @include media-breakpoint-down(sm) {
    line-height: 43px;
    span {
      font-size: 18px;
      line-height: 21px;
    }
  }
  // media !!
}

.common_bg {
  &:after,
  &:before {
    content: "";
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: -1;
  }

  &:after {
    background-image: url("../#{$imageurl}/affilliate-bg-top.png");
    background-position: top left;
    left: 0;
    background-size: 18%;
  }

  &:before {
    background-image: url("../#{$imageurl}/affilliate-bg-bottom.png");
    right: 0;
    background-size: 18%;
    background-position: bottom right;
  }
  @include media-breakpoint-down(sm) {
  }
}

.revenuLoop {
  padding: 50px 0px;
  // height:100vh;
  .section_head {
    margin-bottom: 65px;
  }
  &__link {
    color: #29bdf5;
    font-size: 38px;
    line-height: 46px;
    font-family: font(sb);

    a {
      color: #006094;
      text-decoration: underline;
      @include transition(0.3s);

      &:hover {
        color: lighten($color: #006094, $amount: 10%);
      }
    }
  }
  &_cnt {
    max-width: 500px;
    margin-right: 80px;
    h5 {
      color: #29bdf5;
      margin-top: 24px;
      padding-left: 23px;
      font-size: 30px;
      line-height: 36px;
      a {
        color: #29bdf5;
        font-family: font(bd);
      }
    }
  }
  &__img {
    margin: 10px 0px 12px;
    img {
      max-height: 460px;
    }
  }
  // media
  @include media-breakpoint-down(xxl) {
    padding: 40px 0px;
    &__link {
      font-size: 28px;
      line-height: 36px;
    }
  }
  @include media-breakpoint-down(xl) {
    padding: 0px 0px 102px;
    text-align: center;
    &__link {
      font-size: 20px;
      line-height: 24px;
      color: #006094;
    }
    &__img {
      margin: 25px 0;
      img {
        max-height: 345px;
        margin: 0 auto;
        display: block;
      }
    }
    &_cnt {
      max-width: 420px;
      margin-right: 0;
      margin: 0 auto;
      h5 {
        margin-top: 25px;
        padding-left: 0;
        font-size: 20px;
        line-height: 24px;
        color: #006094;
        font-weight: 600;
      }
    }
  }
  @include media-breakpoint-down(sm) {
    padding: 35px 0px;
    .section_head {
      margin-bottom: 10px;
    }
    &__link {
      font-size: 19px;
      line-height: 23px;
      color: color(600);
    }
    &__img {
      margin: 20px 0 40px;
      img {
        max-height: 100%;
      }
    }
    &_cnt {
      h5 {
        font-size: 16px;
        margin-top: 0;
        color: color(600);
        line-height: 20px;
        font-family: font(sb);
      }
    }
  }
  // media !!
}

.howItwork {
  padding: 63px 0px 70px;
  color: color(50);
  background-color: #00adef;
  position: relative;
  // height:100vh;
  .container {
    @media (min-width: 1550px) {
      max-width: 1512px;
    }
  }
  &:after,
  &:before {
    content: "";
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &:after {
    background-image: url("../#{$imageurl}/howitwork_bg.jpg");
    background-position: top left;
    top: 0;
    left: 0;
  }

  &:before {
    background-image: url("../#{$imageurl}/howitwork_bg.jpg");
    right: 0;
    background-position: right bottom;
    top: 0;
  }

  &__head {
    font-family: font(sb);
    font-size: 36px;
    text-align: center;
    margin-bottom: 60px;
    z-index: 1;
    position: relative;
  }

  &__box {
    background-color: #0081c7;
    padding: 24px 30px;
    position: relative;
    height: 410px;
    z-index: 1;
    overflow: hidden;

    h3 {
      font-size: 28px;
      line-height: 35px;
      font-family: font(bd);
      margin-bottom: 15px;
    }

    p {
      font-size: 19px;
      line-height: 28px;
      font-family: font(rg);
      letter-spacing: 0.016em;
    }

    &__over {
      background-color: #0081c7;
      text-align: right;
      position: absolute;
      width: 100%;
      left: 0;
      height: 198px;
      bottom: 0;
      padding-right: 32px;
      padding-bottom: 32px;
      display: flex;
      justify-content: end;
      align-items: end;
      @include transition(0.3s);

      &__inner {
        // position: relative;
        &:after {
          content: "";
          height: 3px;
          width: 100%;
          background: color(50);
          -webkit-transform: skew(0deg, -10deg);
          transform: skew(0deg, -15deg);
          position: absolute;
          top: -40px;
          left: 0;
        }

        &:before {
          background-color: #0081c7;
          content: "";
          height: 100px;
          width: 100%;
          top: -37px;
          left: 0;
          position: absolute;
          -webkit-transform: skew(0deg, -10deg);
          transform: skew(0deg, -15deg);
        }
      }

      img {
        max-width: 299px;
        position: relative;
      }
    }

    &:hover {
      .howItwork__box__over {
        transform: translateY(300px);
      }
    }
  }
  // media
  @include media-breakpoint-down(xxl) {
    padding: 53px 0px 60px;
    &:after,
    &:before {
      background-size: 220%;
    }
    &__box {
      h3 {
        font-size: 22px;
      }
      p {
        font-size: 15px;
      }
      &__over {
        img {
          max-width: 285px;
        }
      }
    }
  }
  @include media-breakpoint-down(xl) {
    padding: 67px 0px 60px;
    &__head {
      margin-bottom: 53px;
    }
    &::after,
    &:before {
      background-size: 225%;
      width: 50%;
    }
    .container {
      max-width: 100%;
    }
    &__box__over__inner:before {
      display: none;
    }
    &__box__over__inner:after {
      display: none;
    }
    .howItwork__box__over__inner {
      img {
        max-height: 268px;
        margin: 50px auto 0;
        display: block;
      }
    }
    &__box {
      padding: 0;
      height: auto;
      p {
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 40px;
      }
    }
    &_accordion {
      z-index: 1;
      position: relative;
      .accordion {
        z-index: 1;
        &-item {
          border-radius: 0px;
        }
        &-button {
          padding: 29px 22px;
          font-size: 24px;
          line-height: 24px;
          font-family: font(sb);
          background-color: color(550);
          color: color(50);
          border-radius: 0px;
          &:focus {
            box-shadow: none;
            border: 0;
          }
          &:after {
            background: url("../#{$imageurl}/down-arrow.svg");
            background-repeat: no-repeat;
            background-size: 100%;
            height: 10px;
          }
        }
        &-body {
          padding: 24px 22px;
        }
        &-item {
          background-color: color(550);
          border: 0;
          color: #fff;
          &:not(:last-child) {
            margin-bottom: 40px;
          }
        }
      }
    }
  }
  @include media-breakpoint-down(sm) {
    &__head {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 30px;
    }
    &__box {
      p {
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 40px;
      }
    }
    &_accordion {
      .accordion {
        &-button {
          font-size: 22px;
          line-height: 26px;
        }
        &-item {
          &:not(:last-child) {
            margin-bottom: 24px;
          }
        }
      }
    }
  }
  // media !!
}

.dloyalRefer {
  background-image: url("../#{$imageurl}/dloyal_refer_bg.svg");
  background-repeat: no-repeat;
  background-position: center 230px;
  background-size: 100%;
  // height:100vh;
  &__head {
    font-size: 36px;
    text-align: center;
    font-family: font(sb);
    color: #00aeef;
    margin-bottom: 36px;
    span {
      color: #006094;
    }
  }
  &__wrapper {
    max-width: 985px;
    margin: 0 auto;
  }
  &__box {
    background: #00aeef;
    box-shadow: 0px 34px 38px rgba(170, 217, 238, 0.7);
    min-height: 558px;
    padding: 15px 15px 30px;
    position: relative;
    box-shadow: 0px 34px 38px rgba(170, 217, 238, 0.7);
    &__right {
      background-color: #4cc6f4;
      .dloyalRefer__box__inner {
        background: linear-gradient(
          179.6deg,
          rgba(179, 234, 255, 0.4) 0.5%,
          rgba(255, 255, 255, 0) 99.82%
        );
      }
    }
    h4 {
      font-size: 32px;
      line-height: 40px;
      color: #006094;
      font-family: font(bd);
      text-align: center;
      margin-bottom: 36px;
    }
    ul {
      li {
        color: color(50);
        display: flex;
        align-items: center;
        span {
          display: inline-block;
          font-size: 20px;
          line-height: 24px;
          &:first-child {
            margin-right: 9px;
            flex: 0 0 auto;
          }
        }
        &:not(:last-child) {
          margin-bottom: 22px;
        }
        img {
          width: 69px;
        }
      }
    }
    &__inner {
      background: linear-gradient(
        179.6deg,
        rgba(179, 234, 255, 0.4) 0.5%,
        rgba(255, 255, 255, 0) 99.82%
      );
      padding: 28px 35px;
    }
    .btn {
      max-width: 242px;
      margin: 0 auto;
      padding: 16px 32px;
      font-size: 20px;
      position: absolute;
      left: 0;
      right: 0;
      bottom: -20px;
      box-shadow: 0px 11px 15px rgba(207, 228, 237, 0.6);
      em {
        margin-left: 13px;
        font-size: 22px;
      }
    }
  }
  // media
  @include media-breakpoint-down(xl) {
    padding: 72px 0px 128px;
    background-image: none;
    &__head {
      font-size: 36px;
      line-height: 44px;
      margin-bottom: 42px;
    }
    &__box {
      min-height: 100%;
      h4 {
        font-size: 20px;
        line-height: 24px;
        color: #006094;
        margin-bottom: 20px;
      }
      ul {
        li {
          color: color(50);
          display: flex;
          align-items: center;
          span {
            font-size: 16px;
            line-height: 19px;
            &:first-child {
              margin-right: 5px;
            }
          }
          &:not(:last-child) {
            margin-bottom: 12px;
          }
          img {
            width: 37px;
          }
        }
      }
      .btn {
        padding: 10px 15px;
        font-size: 14px;
        line-height: 17px;
        max-width: 155px;
        em {
          margin-left: 5px;
          font-size: 11px;
        }
      }
      &__inner {
        padding: 15px 25px 48px;
      }
    }
  }
  // media !!
}

// footer
.footer{
    padding:55px 0 155px; 
    .container{
         position: relative;
         @media (min-width: 1820px){
            max-width: 1790px;
         }
        &::before, &::after{
            background: #0fb3f0; /* Old browsers */
            background: linear-gradient(to bottom right, #0FB3F0 0%, #fff 52%);; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            content: "";width: 285px;height: 100%; position: absolute;
        }
        &::before{left:6px;top:-6px;}
        &::after{right:6px; bottom:-6px; transform: rotate(180deg);}
    }
    &_wrap{
        background-color: #B2EAFF;padding:20px 25px;z-index: 1; position: relative;
        .btn{font-size: 14px; color:color(500); background-color: color(50); &:hover, &:focus{color:lighten(color(500), 10%)}}
        .twitterIcon{
            width: 56px;height: 56px; color:#21A3F2;font-size: 34px; background-color: color(50); transition: all 0.5s; text-decoration: none;
            &:hover{background-color:#21A3F2 ; color:color(50); border-radius: 10px;}
        }
    }

    @media (max-width: 1599px){
        padding-bottom: 90px;
        &_wrap{
            padding:14px 16px;
        }
        .twitterIcon{width: 45px;height: 45px;font-size: 24px;}
    }
    @include media-breakpoint-down(xl){
        .container{
            padding-left: 17px;padding-right: 17px; max-width: 100%;
            &::before{left:13px;top:-4px;}
            &::after{right:13px;bottom:-4px;}
        }
    }
    @include media-breakpoint-down(sm){
        padding:80px 0 45px;
        .container{
            &::before, &::after{width: 80px;}
        }
        &_wrap{
            text-align: center; padding-bottom: 30px;
            .twitterIcon{width: 56px;height: 56px; font-size: 34px; margin: 0 auto 20px;}
        }
    }
}